import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['pcds']

  submit(e) {
    e.preventDefault()
    const whiteSpace = /\s/gi;
    const pcds = this.pcdsTarget.value.replace(whiteSpace, '')
    const inwardCode = pcds.slice(-3)
    const outwardCode = pcds.slice(0, -3)

    if (outwardCode && inwardCode) {
      const pcdsFormatted = `${outwardCode.toUpperCase()} ${inwardCode.toUpperCase()}`
      Turbolinks.visit(`/webapp/postcodes/${pcdsFormatted}`)
    }
  }

}
