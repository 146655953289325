import { Controller } from "stimulus"
import Chartist from "chartist"

export default class extends Controller {
  static targets = ['values', 'charts']

  drawChart() {
    let options = {
      seriesBarDistance: 0,
      horizontalBars: true,
      low: 0,
      high: this.data.get('max') || 100,
      height: '1em',
      fullWidth:true,
      chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
      axisY: {showGrid: false, showLabel: false, offset: 0},
      axisX: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: true,
        showGrid: false,
        showLabel: false,
        offset: 0
      }
    }

    this.valuesTargets.forEach((el, i) => {
      new Chartist.Bar(this.chartsTargets[i], {series: [[parseInt(el.getAttribute('data-value'))]]}, options)
    })
  }

  connect() {
    this.drawChart()
  }

}
