import { Controller } from "stimulus"
import Chartist from "chartist"

export default class extends Controller {
  static targets = ['imd']

  drawImdChart() {
    const data = {
      series: [
        [
          {x: 1, y: 0 },
          {x: parseInt(this.data.get('imd')), y: 0},
          {x: 32844, y: 0}
        ]
      ]
    }

    const options = {
      low: 1,
      high: 32844,
      height:'4ex',
      width:'60%',
      showPoint: true,
      fullWidth:true,
      chartPadding: {top: 10,right: 10,bottom: 5,left: 10},
      axisY: {showGrid: false, showLabel: false, offset: 0},
      axisX: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: true,
        showGrid: false,
        showLabel: false,
        offset: 0
      }
    }

    let imdChart = new Chartist.Line(this.imdTarget, data, options)

    imdChart.on('draw', data => {
      if(data.type === 'point' && data.index == 1){
        data.group.elem(
          'text',
          {
            x: data.x - this.data.get('imd').length * 3,
            y: data.y - 5
          },
          'ct-label'
        ).text(parseInt(this.data.get('imd')))
      }
    })
  }


  connect() {
    this.drawImdChart()
  }

}
