import { Controller } from "stimulus"
import Chartist from "chartist"

export default class extends Controller {
  static targets = ['quals', 'charts']

  drawQualsChart() {
    let options = {
      seriesBarDistance: 10,
      horizontalBars: true,
      low: 0,
      high: 100,
      height: '1em',
      fullWidth:true,
      chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
      axisY: {showGrid: false, showLabel: false, offset: 0},
      axisX: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: false,
        showGrid: false,
        showLabel: false,
        offset: 0
      }
    }

    this.qualsTargets.forEach((el, i) => {
      new Chartist.Bar(this.chartsTargets[i], {series: [[parseFloat(el.getAttribute('data-quals-local'))], [parseFloat(el.getAttribute('data-quals-national'))]]}, options)
    })
  }


  connect() {
    this.drawQualsChart()
  }

}
